import { createGlobalStyle } from 'styled-components'

interface GlobalBackgroundProps {
  color: string | null
}

const GlobalBackground = createGlobalStyle<GlobalBackgroundProps>`
  body {
    background-color: ${({ theme, color }): string =>
      color || theme.colors.background};
  }`

export default GlobalBackground
