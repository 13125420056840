// The typescript-sort-keys/interface rule is disabled in this file so we can sort from small to big
/* eslint-disable typescript-sort-keys/interface */
import { BreakpointsModel } from '../utils/mediaQueries'

export interface SpacingBreakpoints extends Omit<BreakpointsModel, 'mobile'> {
  root: number
}

export interface Spacings {
  small: SpacingBreakpoints
  medium: SpacingBreakpoints
  large: SpacingBreakpoints
  xlarge: SpacingBreakpoints
  side: SpacingBreakpoints
  sectionMaxWidth: number
}

export const spacings: Spacings = {
  small: {
    root: 32,
    tablet: 32,
    desktop: 48,
    ultrawide: 48,
  },
  medium: {
    root: 64,
    tablet: 80,
    desktop: 80,
    ultrawide: 80,
  },
  large: {
    root: 96,
    tablet: 120,
    desktop: 192,
    ultrawide: 192,
  },
  xlarge: {
    root: 124,
    tablet: 164,
    desktop: 208,
    ultrawide: 208,
  },
  side: {
    root: 32,
    tablet: 48,
    desktop: 48,
    ultrawide: 48,
  },
  sectionMaxWidth: 1440,
}
