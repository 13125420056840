// Color names based on http://chir.ag/projects/name-that-color/

enum Colors {
  ALABASTER = '#f8f8f8',
  ALTO = '#dedede',
  BLACK = '#000000',
  BLACK_0 = 'rgba(0, 0, 0, 0)',
  BLACK_20 = 'rgba(0, 0, 0, 0.2)',
  BLACK_50 = 'rgba(0, 0, 0, 0.5)',
  OSLO_GREY = '#939598',
  SCORPION = '#5f5f5f',
  SHIP_GREY = '#414042',
  WHITE = '#ffffff',
}

export default Colors
