import { usePresence } from 'framer-motion'
import React, { FunctionComponent, useEffect } from 'react'
import styled, { css, SimpleInterpolation } from 'styled-components'
import useScrollPosition from '../../../hooks/use-scroll-position/useScrollPosition'

const AnimationWrapper: FunctionComponent = ({ children }) => {
  const [isPresent, safeToRemove] = usePresence()
  const scrollPosition = useScrollPosition()

  useEffect(() => {
    if (!isPresent) {
      safeToRemove && safeToRemove()
    }
  }, [isPresent])

  return (
    <StyledAnimationWrapper
      present={isPresent}
      style={scrollPosition > 0 ? { top: `-${scrollPosition}px` } : {}}
    >
      {children}
    </StyledAnimationWrapper>
  )
}

interface StyledAnimationWrapperProps {
  present: boolean
}

const StyledAnimationWrapper = styled.div<StyledAnimationWrapperProps>`
  ${({ present }): SimpleInterpolation =>
    !present &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
    `}
`

export default AnimationWrapper
