import { FunctionComponent, useEffect } from 'react'
import { Actions } from '../../../contexts/app-state/AppStateReducer'
import { useAppState } from '../../../contexts/app-state/AppStateContext'

const IPCheck: FunctionComponent = () => {
  const [, dispatch] = useAppState()

  useEffect(() => {
    fetch('https://pro.ip-api.com/json/?fields=57346&key=xlQz0l9p8FpY91F')
      .then((response) => response.json())
      .then(({ countryCode }: { countryCode: string }) => {
        dispatch({
          payload: countryCode.toLowerCase(),
          type: Actions.SET_COUNTRY_CODE,
        })
      })
      // @TODO add error handling
      // Adblockers might block this call
      .catch((e) => {
        console.error(e)
      })
  }, [])

  return null
}

export default IPCheck
