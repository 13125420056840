import { useEffect, useRef } from 'react'

const useScrollPosition = (): number => {
  const scrollPosition = useRef<number>(0)

  useEffect(() => {
    const onScroll = (): void => {
      scrollPosition.current = window.scrollY
    }
    window.addEventListener('scroll', onScroll)

    return (): void => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return scrollPosition.current || 0
}

export default useScrollPosition
