import { useStaticQuery, graphql } from 'gatsby'
import { ImageNodeData, Images } from '../../contexts/images/ImagesContext'

interface ImageNode {
  childImageSharp?: ImageNodeData
  relativePath: string
}

interface ImagesQueryResponse {
  images: {
    nodes: ImageNode[]
  }
  nextArtPieces: {
    nodes: ImageNode[]
  }
  nextArtPiecesNarrow: {
    nodes: ImageNode[]
  }
}

const useQueriedImagesData = (): Images => {
  /**
   * Parse data to desired format
   * @param nodes
   */
  const parseData = (nodes: ImageNode[]): Images => {
    const images: Images = {}

    nodes.forEach((node) => {
      if (node.childImageSharp) {
        const fluid = node.childImageSharp.fluid
        const fixed = node.childImageSharp.fixed

        images[node.relativePath] = {
          fluid,
          fixed,
        }
      }
    })

    return images
  }

  const imagesData = useStaticQuery<ImagesQueryResponse>(
    graphql`
      query ImageData {
        images: allFile(
          filter: {
            extension: { in: ["jpg", "png"] }
            relativeDirectory: {
              regex: "/images/"
              nin: ["images/next-art-piece"]
            }
          }
        ) {
          nodes {
            relativePath
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
              fixed {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        nextArtPieces: allFile(
          filter: { relativeDirectory: { in: ["images/next-art-piece"] } }
        ) {
          nodes {
            relativePath
            childImageSharp {
              fluid(
                maxWidth: 3840
                srcSetBreakpoints: [1440, 1920, 2880, 3840]
              ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        nextArtPiecesNarrow: allFile(
          filter: {
            relativeDirectory: { in: ["images/next-art-piece-narrow"] }
          }
        ) {
          nodes {
            relativePath
            childImageSharp {
              fluid(maxWidth: 3840, srcSetBreakpoints: [375, 768, 750, 1536]) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `,
  )

  return parseData([
    ...imagesData.images.nodes,
    ...imagesData.nextArtPieces.nodes,
    ...imagesData.nextArtPiecesNarrow.nodes,
  ])
}

export default useQueriedImagesData
