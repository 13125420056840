export enum PAGE_TEMPLATE {
  HOME = 'HOME',
  PAGE = 'PAGE',
  PROJECT = 'PROJECT',
}

const getTemplateByPathname = (pathname: string): PAGE_TEMPLATE | undefined => {
  if (isHome(pathname)) return PAGE_TEMPLATE.HOME
  if (isProject(pathname)) return PAGE_TEMPLATE.PROJECT
  if (isPage(pathname)) return PAGE_TEMPLATE.PAGE

  return
}

export const isHome = (pathname: string): boolean => pathname === '/'
export const isProject = (pathname: string): boolean =>
  pathname.split('/').filter((x) => x)[0] === 'projects'
export const isPage = (pathname: string): boolean =>
  pathname.endsWith('/')
    ? pathname.slice(0, -1).split('/').length === 2
    : pathname.split('/').length === 2

export default getTemplateByPathname
