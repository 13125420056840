import Colors from '../tokens/Colors'
import { DefaultTheme } from 'styled-components'

const defaultTheme: DefaultTheme = {
  borderRadius: '4px',

  colors: {
    primary: '#00a4d6',
    background: Colors.WHITE,
  },

  accents: {
    primary: '#00b1d5',
    background: Colors.BLACK,
  },

  text: {
    fontFamily: 'Kvadrat Neuzeit S, sans-serif',
    color: Colors.SHIP_GREY,
  },

  title: {
    fontFamily: 'Kvadrat Neuzeit S, sans-serif',
  },
}

export default defaultTheme
