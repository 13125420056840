import Dictionary from '../../types/Dictionary'
import React from 'react'

export interface DictionaryContextInterface {
  entries: Dictionary
  get: (key: string, defaultValue?: string) => string
}

/**
 * Helper method to get dictionary entry by key
 * @param dictionary
 * @param key
 * @param defaultValue
 */
export const getDictionaryEntryByKey = (
  dictionary: Dictionary,
  key: string,
  defaultValue?: string,
): string => {
  // Double check for the dictionary
  if (!dictionary) {
    return defaultValue ? defaultValue : `[${key}]`
  }

  const result = dictionary[key]

  if (result) {
    // Return the result if it's available
    return result
  } else if (defaultValue) {
    // Else return the default value
    return defaultValue
  } else {
    // Else return an indicator of missing dictionary item
    return `[${key}]`
  }
}

/**
 * The DictionaryItem context
 */
export const DictionaryContext = React.createContext<
  DictionaryContextInterface
>({
  entries: {},
  get: (key) => key,
})

export const DictionaryProvider = DictionaryContext.Provider
export const DictionaryConsumer = DictionaryContext.Consumer

export default DictionaryContext
