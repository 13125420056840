/**
 * @see https://codesandbox.io/s/xinfz?file=/src/useScrollDirection.js:0-1395
 */
import { useEffect, useState } from 'react'

export enum ScrollDirections {
  down = 'down',
  up = 'up',
}

const useScrollDirection = ({
  initialDirection = ScrollDirections.down,
  thresholdPixels = 1,
}: {
  initialDirection?: ScrollDirections
  thresholdPixels?: number
} = {}): ScrollDirections => {
  const [scrollDir, setScrollDir] = useState(initialDirection)

  useEffect(() => {
    const threshold = thresholdPixels || 0
    let lastScrollY = window.pageYOffset
    let ticking = false

    const updateScrollDir = (): void => {
      const scrollY = window.pageYOffset

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        // We haven't exceeded the threshold
        ticking = false
        return
      }

      setScrollDir(
        scrollY > lastScrollY ? ScrollDirections.down : ScrollDirections.up,
      )
      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    const onScroll = (): void => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    window.addEventListener('scroll', onScroll)

    return (): void => window.removeEventListener('scroll', onScroll)
  }, [initialDirection, thresholdPixels])

  return scrollDir
}

export default useScrollDirection
