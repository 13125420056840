// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-pages-content-page-tsx": () => import("./../../../src/components/pages/ContentPage.tsx" /* webpackChunkName: "component---src-components-pages-content-page-tsx" */),
  "component---src-components-pages-error-404-page-tsx": () => import("./../../../src/components/pages/Error404Page.tsx" /* webpackChunkName: "component---src-components-pages-error-404-page-tsx" */),
  "component---src-components-pages-home-page-tsx": () => import("./../../../src/components/pages/HomePage.tsx" /* webpackChunkName: "component---src-components-pages-home-page-tsx" */),
  "component---src-components-pages-project-page-tsx": () => import("./../../../src/components/pages/ProjectPage.tsx" /* webpackChunkName: "component---src-components-pages-project-page-tsx" */)
}

