import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { ThemeProvider } from 'styled-components'
import { AppStateProvider } from '../../../contexts/app-state/AppStateContext'
import {
  DictionaryProvider,
  getDictionaryEntryByKey,
} from '../../../contexts/dictionary/DictionaryContext'
import { ImagesProvider } from '../../../contexts/images/ImagesContext'
import useQueriedImagesData from '../../../hooks/use-queried-images-data/useQueriedImagesData'
import defaultTheme from '../../../themes/defaultTheme'
import Colors from '../../../tokens/Colors'
import Dictionary from '../../../types/Dictionary'
import getTemplateByPathname, {
  isHome,
  PAGE_TEMPLATE,
} from '../../../utils/getTemplateByPathname'
import CookieBar from '../../molecules/cookie-bar/CookieBar'
import IPCheck from '../../molecules/ip-check/IPCheck'
import { ProjectProps } from '../../pages/ProjectPage'
import Header from '../header/Header'
import Main from '../main/Main'
import GlobalBackground from './global-styles/GlobalBackground'
import GlobalFonts from './global-styles/GlobalFonts'
import GlobalHeight from './global-styles/GlobalHeight'
import GlobalReset from './global-styles/GlobalReset'
import GlobalText from './global-styles/GlobalText'

interface AppProps {
  location: Location
  pageContext: {
    audioTour?: string
    dictionary: Dictionary
    projects: ProjectProps[]
  }
  rootSelector?: string
}

const App: FunctionComponent<AppProps> = ({
  children,
  location,
  rootSelector = '#___gatsby, #gatsby-focus-wrapper',
  pageContext,
}) => {
  const { dictionary, projects } = pageContext
  const images = useQueriedImagesData()
  const [template, setTemplate] = useState<PAGE_TEMPLATE>()

  const getDictionaryEntry = useCallback(
    (key: string, defaultValue?: string): string =>
      getDictionaryEntryByKey(dictionary || [], key, defaultValue),
    [dictionary],
  )

  const getImage = useCallback(
    (fileName: string | string[]) => {
      if (Array.isArray(fileName)) {
        return fileName
          .map((fileName) => images[`images/${fileName}`])
          .filter((x) => x)
      }

      return images[`images/${fileName}`]
    },
    [images],
  )

  useEffect(() => {
    setTemplate(getTemplateByPathname(location.pathname))
  }, [location.pathname])

  return (
    <ThemeProvider theme={defaultTheme}>
      <AppStateProvider>
        <DictionaryProvider
          value={{
            entries: dictionary || {},
            get: getDictionaryEntry,
          }}
        >
          <ImagesProvider
            value={{
              images: images || {},
              getImage,
            }}
          >
            <GlobalReset />
            <GlobalHeight rootSelector={rootSelector} />
            <GlobalBackground
              color={isHome(location.pathname) ? Colors.BLACK : null}
            />
            <GlobalFonts />
            <GlobalText />
            <Header
              audioTour={pageContext.audioTour}
              isHome={template === PAGE_TEMPLATE.HOME}
              isPage={template === PAGE_TEMPLATE.PAGE}
              isProject={template === PAGE_TEMPLATE.PROJECT}
            />
            <Main location={location} projects={projects}>
              {children}
            </Main>
            <CookieBar />
            <IPCheck />
          </ImagesProvider>
        </DictionaryProvider>
      </AppStateProvider>
    </ThemeProvider>
  )
}

export default App
