// The typescript-sort-keys/interface rule is disabled in this file so we can sort from small to big
/* eslint-disable typescript-sort-keys/interface */
export interface BreakpointsModel {
  mobile: number
  tablet: number
  desktop: number
  ultrawide: number
}

export const breakpoints: BreakpointsModel = {
  mobile: 480,
  tablet: 768,
  desktop: 1024,
  ultrawide: 1920,
}

export const createMediaQuery = ({
  maxWidth,
  minWidth,
}: {
  maxWidth?: number
  minWidth: number
}): string =>
  `@media (min-width: ${minWidth}px)${
    maxWidth !== undefined ? ` and (max-width: ${maxWidth}px)` : ''
  }`

export const mediaQueries = {
  tablet: createMediaQuery({ minWidth: breakpoints.tablet }),
  desktop: createMediaQuery({ minWidth: breakpoints.desktop }),
  ultrawide: createMediaQuery({ minWidth: breakpoints.ultrawide }),
}

export const isMobileOrDown = (): boolean =>
  window.innerWidth <= breakpoints.mobile

export const isDesktopOrDown = (): boolean =>
  window.innerWidth <= breakpoints.desktop
