import { useState } from 'react'

export const useCookie = (
  key: string,
  initialValue: boolean,
): [boolean, (value: boolean, numberOfDays?: number) => void] => {
  const [cookieValue, setCookieValue] = useState<boolean>(
    typeof document !== 'undefined'
      ? (): boolean => {
          const item = document.cookie
            .split('; ')
            .reduce((total, currentCookie) => {
              const item = currentCookie.split('=')
              const storedKey = item[0]
              const storedValue = item[1]

              return key === storedKey ? decodeURIComponent(storedValue) : total
            }, '')

          return item ? (JSON.parse(item) as boolean) : initialValue
        }
      : initialValue,
  )

  const setCookie = (value: boolean, numberOfDays = 365): void => {
    setCookieValue(value)
    const expireDate = new Date()

    expireDate.setTime(
      expireDate.getTime() + numberOfDays * 60 * 60 * 24 * 1000,
    )

    document.cookie = `${key}=${value.toString()}; expires=${expireDate.toUTCString()}; path=/`
  }

  return [cookieValue, setCookie]
}

export default useCookie
