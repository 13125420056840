// https://medium.com/@JackPu/how-to-detect-browser-support-webp-446b8f91504
// https://developers.google.com/speed/webp/faq
export const isWebPSupported = (): Promise<void> =>
  new Promise((resolve, reject) => {
    const img = new Image()

    img.onload = (): void => {
      resolve()
    }

    img.onerror = (): void => {
      reject()
    }

    img.src =
      'data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA'
  })
