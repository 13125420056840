import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import styled, { css, SimpleInterpolation } from 'styled-components'
import iconCursorMinus from '../../../images/cursor/icon-cursor-minus.png'
import iconCursorMinus2x from '../../../images/cursor/icon-cursor-minus@2x.png'
import iconCursorPlus from '../../../images/cursor/icon-cursor-plus.png'
import iconCursorPlus2x from '../../../images/cursor/icon-cursor-plus@2x.png'
import { rem } from '../../../utils/rem'
import { useAppState } from '../../../contexts/app-state/AppStateContext'

export type CursorOverlayTypes = 'maximize' | 'minimize'

const CursorOverlay: FunctionComponent = () => {
  const [{ cursorOverlayType }] = useAppState()

  const [position, setPosition] = useState({
    left: 0,
    top: 0,
  })

  const onMouseMove = useCallback((event: MouseEvent) => {
    setPosition({
      left: event.clientX,
      top: event.clientY,
    })
  }, [])

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove)

    return (): void => {
      window.removeEventListener('mousemove', onMouseMove)
    }
  }, [onMouseMove])

  if (cursorOverlayType === null || cursorOverlayType === undefined) {
    return null
  }

  return (
    <StyledCursorOverlay
      left={position.left}
      top={position.top}
      type={cursorOverlayType}
    />
  )
}

const getIconStyle = (type: CursorOverlayTypes): SimpleInterpolation => {
  switch (type) {
    case 'maximize':
      return css`
        background-image: url(${iconCursorPlus});

        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
          background-image: url(${iconCursorPlus2x});
        }
      `
    case 'minimize':
      return css`
        background-image: url(${iconCursorMinus});

        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
          background-image: url(${iconCursorMinus2x});
        }
      `

    default:
      return css``
  }
}

interface StyledCursorOverlayProps {
  left: number
  top: number
  type: CursorOverlayTypes
}

const StyledCursorOverlay = styled.div.attrs<StyledCursorOverlayProps>(
  ({ left, top }) => ({
    style: {
      left: rem(left),
      top: rem(top),
    },
  }),
)<StyledCursorOverlayProps>`
  ${({ type }): ReturnType<typeof getIconStyle> => getIconStyle(type)}
  position: fixed;
  background-position: center;
  width: ${rem(78)};
  height: ${rem(78)};
  background-size: ${rem(78)} ${rem(78)};
  pointer-events: none;
  z-index: 100000;
  transform: translate3d(-50%, -50%, 0);
`

export default CursorOverlay
