import IconButton, { IconButtonIcons } from '../../atoms/icon-button/IconButton'
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Actions } from '../../../contexts/app-state/AppStateReducer'
import { useAppState } from '../../../contexts/app-state/AppStateContext'

export interface AudioTourProps {
  src: string
}

const AudioTour: FunctionComponent<AudioTourProps> = ({ src }) => {
  const audio = useRef<HTMLAudioElement | null>(null)
  const button = useRef<HTMLButtonElement | null>(null)
  const [isPaused, setIsPaused] = useState(true)
  const [{ isAudioTourPlaying }, dispatch] = useAppState()

  const onEnded = useCallback(() => {
    setIsPaused(true)
  }, [])

  const pauseAudioTour = useCallback(() => {
    audio.current?.pause()
    setIsPaused(true)

    dispatch({
      payload: false,
      type: Actions.SET_IS_AUDIO_TOUR_PLAYING,
    })
  }, [audio.current])

  const playAudioTour = useCallback(() => {
    void audio.current?.play().then(() => {
      setIsPaused(false)

      dispatch({
        payload: true,
        type: Actions.SET_IS_AUDIO_TOUR_PLAYING,
      })
    })
  }, [audio.current])

  const onToggleIsPlaying = useCallback(() => {
    if (audio.current?.paused) {
      playAudioTour()
    } else {
      pauseAudioTour()
    }
  }, [])

  useEffect(() => {
    audio.current?.addEventListener('ended', onEnded)

    return (): void => {
      // Remove event listener on component unmount
      audio.current?.removeEventListener('ended', onEnded)
    }
  }, [audio])

  useEffect(() => {
    if (!isAudioTourPlaying && !audio.current?.paused) {
      pauseAudioTour()
    }
  }, [isAudioTourPlaying])

  return (
    <Fragment>
      <audio ref={audio} src={src}>
        Your browser does not support the
        <code>audio</code> element.
      </audio>
      <IconButton
        icon={isPaused ? IconButtonIcons.headphones : IconButtonIcons.pause}
        onClick={onToggleIsPlaying}
        ref={button}
      />
    </Fragment>
  )
}

export default AudioTour
