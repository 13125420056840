import { createGlobalStyle } from 'styled-components'
import kvadratNeuzeitSBold from '../../../../fonts/kvadrat-neuzeit-s/bold.woff2'
import kvadratNeuzeitSItalic from '../../../../fonts/kvadrat-neuzeit-s/italic.woff2'
import kvadratNeuzeitSRegular from '../../../../fonts/kvadrat-neuzeit-s/regular.woff2'

const GlobalFonts = createGlobalStyle`
  @font-face {
    src: url(${kvadratNeuzeitSBold}) format('woff2');
    font-family: 'Kvadrat Neuzeit S';
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    src: url(${kvadratNeuzeitSItalic}) format('woff2');
    font-family: 'Kvadrat Neuzeit S';
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    src: url(${kvadratNeuzeitSRegular}) format('woff2');
    font-family: 'Kvadrat Neuzeit S';
    font-weight: 400;
    font-style: normal;
  }
`

export default GlobalFonts
