import Colors from '../../../tokens/Colors'
import { grid } from '../../../utils/grid'
import IconButton, { IconButtonIcons } from '../../atoms/icon-button/IconButton'
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import useScrollDirection, {
  ScrollDirections,
} from '../../../hooks/use-scroll-direction/useScrollDirection'
import NavigationItem from '../../atoms/navigation-item/NavigationItem'
import AudioTour from '../../molecules/audio-tour/AudioTour'
import { Link } from 'gatsby'
import logo from '../../../images/logo.svg'
import { mediaQueries } from '../../../utils/mediaQueries'
import { motion } from 'framer-motion'
import { rem } from '../../../utils/rem'
import styled from 'styled-components'
import useBreakPoints from '../../../hooks/use-breakpoints/useBreakPoints'
import useDictionary from '../../../hooks/use-dictionary/useDictionary'
import { useAppState } from '../../../contexts/app-state/AppStateContext'

export interface HeaderProps {
  audioTour?: string
  isHome?: boolean
  isPage?: boolean
  isProject?: boolean
}

const Header: FunctionComponent<HeaderProps> = ({
  audioTour,
  isHome,
  isPage,
  isProject,
}) => {
  const [{ isIntroViewed }] = useAppState()
  const threshold = 10
  const { isTablet } = useBreakPoints()
  const [thresholdExceeded, setThresholdExceeded] = useState(false)
  const direction = useScrollDirection()
  const [showHeader, setShowHeader] = useState(true)
  const dictionary = useDictionary()

  const handleScroll = useCallback(() => {
    const scroll = window.pageYOffset

    if (scroll > threshold !== thresholdExceeded) {
      setThresholdExceeded(scroll > threshold)
    }
  }, [threshold, thresholdExceeded])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return (): void => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {
    if (!isIntroViewed && isHome) {
      setShowHeader(false)
    } else {
      setShowHeader(!thresholdExceeded || direction === ScrollDirections.up)
    }
  }, [direction, isIntroViewed, isHome, thresholdExceeded])

  return (
    <Wrapper
      animate={showHeader ? 'visible' : 'hidden'}
      initial="hidden"
      transition={{ ease: showHeader ? 'easeOut' : 'easeIn' }}
      variants={{
        hidden: { opacity: 0, y: -80 },
        visible: { opacity: 1, y: 0 },
      }}
    >
      <StyledBackground
        animate={thresholdExceeded ? 'visible' : 'hidden'}
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
      />

      <Grid>
        <StyledLink to="/">
          <Logo
            alt={dictionary.get('knit')}
            animate={
              isHome && !isTablet && thresholdExceeded ? 'hidden' : 'visible'
            }
            src={logo}
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1 },
            }}
          />
        </StyledLink>
        <Navigation isProject={!!isProject}>
          {(isHome || isPage) && (
            <Fragment>
              <NavigationItem to="/about">
                {dictionary.get('about')}
              </NavigationItem>
              <NavigationItem to="/talks">
                {dictionary.get('talks')}
              </NavigationItem>
            </Fragment>
          )}

          {!isHome && !isPage && (
            <Fragment>
              {audioTour && <AudioTour src={audioTour} />}
              <StyledIconButton icon={IconButtonIcons.marker} to="/" />
            </Fragment>
          )}
        </Navigation>
      </Grid>
    </Wrapper>
  )
}

export default Header

const StyledIconButton = styled(IconButton)`
  margin-left: ${rem(16)};
`

const StyledLink = styled(Link)`
  grid-column: 1 / span 2;
`

const StyledBackground = styled(motion.div)`
  height: ${rem(104)};
  width: 100%;
  position: absolute;
  background: linear-gradient(
    180deg,
    ${Colors.BLACK_50} 0%,
    ${Colors.BLACK_0} 100%
  );
  z-index: 0;
  pointer-events: none;
`

const Wrapper = styled(motion.header)`
  position: fixed;
  width: 100%;
  z-index: 3;
`

const Grid = styled.div`
  ${grid()};
  padding-left: ${rem(16)};
  padding-right: ${rem(16)};
  z-index: 1;
  position: relative;
  margin-top: ${rem(32)};

  ${mediaQueries.tablet} {
    margin-top: ${rem(32)};
  }

  ${mediaQueries.desktop} {
    margin-top: ${rem(40)};
  }
`

const Logo = styled(motion.img)`
  cursor: pointer;
`

interface NavigationProps {
  isProject: boolean
}

const Navigation = styled.nav<NavigationProps>`
  display: flex;
  grid-column: 3 / span 2;
  align-items: center;
  width: 100%;
  justify-content: flex-end;

  ${mediaQueries.tablet} {
    justify-content: ${({ isProject }): string =>
      isProject ? 'flex-end' : 'flex-start'};
    grid-column: 3 / span 14;
  }
`
