import { Variants } from 'framer-motion'
import { PAGE_TEMPLATE } from '../../../utils/getTemplateByPathname'

export const pageTransition = (
  template?: PAGE_TEMPLATE,
  previousTemplate?: PAGE_TEMPLATE,
): Variants => ({
  enter: {
    display: 'block',
    opacity: 1,
    transition: {
      duration: 0.6,
    },
    transitionEnd: {
      position: 'relative',
      zIndex: 2,
    },
    zIndex: 1,
  },
  exit: {
    opacity: 0,
    position: 'fixed',
    transition: {
      duration: 0.6,
      when: 'afterChildren',
    },
    transitionEnd: {
      display: 'none',
    },
    zIndex: 3,
  },
})
