import * as React from 'react'
import { FluidObject, FixedObject } from 'gatsby-image'

export type ImageNodeData = {
  fixed?: FixedObject
  fluid: FluidObject
}

export interface Images {
  [key: string]: ImageNodeData
}

export interface ImagesContextInterface {
  getImage: (
    fileName: string | string[],
  ) => ImageNodeData | ImageNodeData[] | null
  images: Images
}

/**
 * The Images context
 */
export const ImagesContext = React.createContext<ImagesContextInterface>({
  images: {},
  getImage: (fileName: string | string[]) => null,
})

export const ImagesProvider = ImagesContext.Provider
export const ImagesConsumer = ImagesContext.Consumer

export default ImagesContext
