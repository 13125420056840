import { CursorOverlayTypes } from '../../components/organisms/cursor-overlay/CursorOverlay'
import { ProjectProps } from '../../components/pages/ProjectPage'
import { PAGE_TEMPLATE } from '../../utils/getTemplateByPathname'

export interface State {
  countryCode: string | null
  cursorOverlayType: CursorOverlayTypes | null
  isAudioTourPlaying: boolean
  isIntroViewed: boolean
  isWebPSupported?: boolean
  pageTemplate?: PAGE_TEMPLATE
  previousPageTemplate?: PAGE_TEMPLATE
  projects: ProjectProps[]
  zoomImageSrc: string | null
}

const initialState: State = {
  countryCode: null,
  cursorOverlayType: null,
  isAudioTourPlaying: false,
  isIntroViewed: false,
  projects: [],
  zoomImageSrc: null,
}

export default initialState
