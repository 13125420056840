import React, { forwardRef, MouseEvent } from 'react'
import Colors from '../../../tokens/Colors'
import iconHeadphones from '../../../images/icon-headphones.svg'
import iconMarker from '../../../images/icon-marker.svg'
import iconPause from '../../../images/icon-pause.svg'
import iconPlay from '../../../images/icon-play.svg'
import { Link } from 'gatsby'
import { rem } from '../../../utils/rem'
import styled from 'styled-components'

export enum IconButtonIcons {
  headphones = 'headphones',
  marker = 'marker',
  pause = 'pause',
  play = 'play',
}

interface IconButtonProps {
  className?: string
  icon?: IconButtonIcons
  onClick?: (event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void
  to?: string
}

const getIcon = (icon: IconButtonIcons): string | null => {
  switch (icon) {
    case IconButtonIcons.headphones:
      return iconHeadphones

    case IconButtonIcons.marker:
      return iconMarker

    case IconButtonIcons.pause:
      return iconPause

    case IconButtonIcons.play:
      return iconPlay

    default:
      return null
  }
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    { className, icon = IconButtonIcons.headphones, to, ...props },
    forwardRef,
  ) => {
    const iconSrc = getIcon(icon)

    if (!iconSrc) {
      return null
    }

    return to ? (
      <StyledIconButton as={Link} className={className} to={to} {...props}>
        <Icon src={iconSrc} />
      </StyledIconButton>
    ) : (
      <StyledIconButton className={className} {...props} ref={forwardRef}>
        <Icon src={iconSrc} />
      </StyledIconButton>
    )
  },
)

IconButton.displayName = 'IconButton'

const StyledIconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.BLACK};
  width: ${rem(40)};
  height: ${rem(40)};
  border-radius: 100%;
  transition: background 200ms ease-in;
  border: none;
  cursor: pointer;
  appearance: none;
  outline: none;
  padding: 0;

  &:hover {
    transition: background 200ms ease-out;
    background-color: ${Colors.SCORPION};
  }
`

const Icon = styled.img`
  flex: 0 0 auto;
`

export default IconButton
