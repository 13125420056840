import { LinkGetProps } from '@reach/router'
import { Link } from 'gatsby'
import React, { FunctionComponent, ReactNode, useCallback, useRef } from 'react'
import styled from 'styled-components'
import Colors from '../../../tokens/Colors'
import { rem } from '../../../utils/rem'

interface NavigationItemProps {
  children: ReactNode
  to: string
}

const NavigationItem: FunctionComponent<NavigationItemProps> = ({
  children,
  to,
}) => {
  const active = useRef(false)

  /**
   * Calls up to you to get props for the underlying anchor element.
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  const getProps = useCallback((props: LinkGetProps): {} => {
    active.current = props.isCurrent
    return {}
  }, [])

  return (
    <StyledLink getProps={getProps} to={to}>
      <StyledSpan active={active.current}>{children}</StyledSpan>
    </StyledLink>
  )
}

const StyledSpan = styled.span<{ active: boolean }>`
  padding: ${rem(8)} 0;
  border-bottom: ${({ active }): string =>
    active ? `1px solid ${Colors.WHITE}` : 'none'};
`

const StyledLink = styled(Link)`
  color: ${Colors.WHITE};
  padding: ${rem(12)} ${rem(23)};
  font-size: ${rem(15)};
  font-weight: 700;
  text-decoration: none;
  white-space: nowrap;
`

export default NavigationItem
