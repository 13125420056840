import React, { FunctionComponent, useCallback } from 'react'
import Colors from '../../../tokens/Colors'
import { mediaQueries } from '../../../utils/mediaQueries'
import { motion } from 'framer-motion'
import { rem } from '../../../utils/rem'
import { spacings } from '../../../tokens/Spacings'
import styled from 'styled-components'
import useCookie from '../../../hooks/use-cookie/useCookie'
import useDictionary from '../../../hooks/use-dictionary/useDictionary'

const CookieBar: FunctionComponent = () => {
  const dictionary = useDictionary()
  const [cookieValue, setCookie] = useCookie('hide-cookie-bar', false)

  const acceptCookie = useCallback(() => {
    setCookie(true)
  }, [])

  return (
    <StyledCookieBar
      animate={cookieValue ? 'hidden' : 'visible'}
      initial="hidden"
      variants={{
        hidden: { opacity: 0, y: 75, visibility: 'hidden' },
        visible: { opacity: 1, y: 0, visibility: 'visible' },
      }}
    >
      <Paragraph
        dangerouslySetInnerHTML={{ __html: dictionary.get('cookieMessage') }}
      />
      <Button onClick={acceptCookie}>{dictionary.get('cookieConfirm')}</Button>
    </StyledCookieBar>
  )
}

export default CookieBar

const StyledCookieBar = styled(motion.div)`
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${Colors.ALTO};
  padding: ${rem(16)} ${rem(spacings.side.root)};
  height: ${rem(75)};
  font-size: ${rem(15)};

  ${mediaQueries.tablet} {
    padding: ${rem(16)} ${rem(spacings.side.tablet)};
  }

  ${mediaQueries.desktop} {
    padding: ${rem(16)} ${rem(spacings.side.desktop)};
  }
`

const Paragraph = styled.p`
  color: ${Colors.SHIP_GREY};
  margin-right: ${rem(spacings.small.root)};

  a {
    color: ${Colors.SHIP_GREY};
  }

  ${mediaQueries.tablet} {
    margin-right: ${rem(spacings.small.tablet)};
  }

  ${mediaQueries.desktop} {
    margin-right: ${rem(spacings.small.desktop)};
  }
`

const Button = styled.div`
  border: none;
  background: none;
  cursor: pointer;
  color: ${Colors.OSLO_GREY};

  &:hover {
    color: ${Colors.SHIP_GREY};
  }
`
