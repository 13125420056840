import { breakpoints } from '../../utils/mediaQueries'
import useWindowSize from '../use-window-size/useWindowSize'

interface Breakpoints {
  isDesktop: boolean
  isTablet: boolean
  isUltrawide: boolean
}

const useBreakPoints = (): Breakpoints => {
  const [width] = useWindowSize()

  return {
    isDesktop: width >= breakpoints.desktop,
    isTablet: width >= breakpoints.tablet,
    isUltrawide: width >= breakpoints.ultrawide,
  }
}

export default useBreakPoints
