import { AnimatePresence, motion } from 'framer-motion'
import React, { FunctionComponent, ReactNode, useEffect } from 'react'
import styled, { css, SimpleInterpolation } from 'styled-components'
import getTemplateByPathname, {
  isHome,
} from '../../../utils/getTemplateByPathname'
import { Actions } from '../../../contexts/app-state/AppStateReducer'
import AnimationWrapper from '../../atoms/animation-wrapper/AnimationWrapper'
import CursorOverlay from '../cursor-overlay/CursorOverlay'
import ImageZoom from '../image-zoom'
import { isWebPSupported } from '../../../utils/isWebPSupported'
import { pageTransition } from './animations'
import { ProjectProps } from '../../pages/ProjectPage'
import shuffleProjects from '../../../utils/shuffleProjects'
import { useAppState } from '../../../contexts/app-state/AppStateContext'

interface MainProps {
  children: ReactNode
  location: Location
  projects: ProjectProps[]
}

const Main: FunctionComponent<MainProps> = ({
  children,
  location,
  projects,
}) => {
  const [
    { cursorOverlayType, isIntroViewed, pageTemplate, previousPageTemplate },
    dispatch,
  ] = useAppState()

  const isCursorOverlayActive = cursorOverlayType !== null

  /**
   * Set page template
   */
  useEffect(() => {
    dispatch({
      payload: getTemplateByPathname(location.pathname),
      type: Actions.SET_PAGE_TEMPLATE,
    })
  }, [location.pathname])

  /**
   * Set shuffled projects to app state
   */
  useEffect(() => {
    dispatch({
      payload: shuffleProjects(projects),
      type: Actions.SET_PROJECTS,
    })
  }, [])

  /**
   * When landing on a different page than home, mark the intro as viewed
   */
  useEffect(() => {
    if (!isHome(location.pathname) && !isIntroViewed) {
      dispatch({
        payload: true,
        type: Actions.SET_IS_INTRO_VIEWED,
      })
    }
  }, [location.pathname])

  /**
   * Set isWebPSupported boolean to app state
   */
  useEffect(() => {
    isWebPSupported()
      .then(() => {
        dispatch({
          payload: true,
          type: Actions.SET_IS_WEB_P_SUPPORTED,
        })
      })
      .catch(() => {
        dispatch({
          payload: false,
          type: Actions.SET_IS_WEB_P_SUPPORTED,
        })
      })
  }, [])

  return (
    <StyledMain
      className={isCursorOverlayActive ? 'hide-cursor' : ''}
      isCursorOverlayActive={isCursorOverlayActive}
    >
      <AnimatePresence>
        <MotionMain
          animate="enter"
          exit="exit"
          key={location.pathname}
          variants={pageTransition(pageTemplate, previousPageTemplate)}
        >
          <AnimationWrapper>{children}</AnimationWrapper>
        </MotionMain>
      </AnimatePresence>
      <ImageZoom />
      <CursorOverlay />
    </StyledMain>
  )
}

interface StyledMainProps {
  isCursorOverlayActive: boolean
}

const StyledMain = styled.div<StyledMainProps>`
  ${({ isCursorOverlayActive }): SimpleInterpolation =>
    isCursorOverlayActive &&
    css`
      > * {
        cursor: none !important;
      }
    `}
`

const MotionMain = styled(motion.main)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
`

export default Main
