import shuffle from 'lodash/shuffle'
import { ProjectProps } from '../components/pages/ProjectPage'

const shuffleProjects = (projects: ProjectProps[]): ProjectProps[] => {
  const shuffledProjects = shuffle(projects)

  return shuffledProjects.map((project, i) => {
    const { artist, meta, slug, title } =
      i + 1 !== shuffledProjects.length
        ? shuffledProjects[i + 1]
        : shuffledProjects[0]
    return {
      ...project,
      next: {
        artist,
        meta,
        slug,
        title,
      },
    }
  })
}

export default shuffleProjects
