import { State } from './initialState'

export enum Actions {
  SET_COUNTRY_CODE = 'SET_COUNTRY_CODE',
  SET_CURSOR_OVERLAY_TYPE = 'SET_CURSOR_OVERLAY_TYPE',
  SET_IS_AUDIO_TOUR_PLAYING = 'SET_IS_AUDIO_TOUR_PLAYING',
  SET_IS_INTRO_VIEWED = 'SET_IS_INTRO_VIEWED',
  SET_IS_WEB_P_SUPPORTED = 'SET_IS_WEB_P_SUPPORTED',
  SET_PAGE_TEMPLATE = 'SET_PAGE_TEMPLATE',
  SET_PROJECTS = 'SET_PROJECTS',
  SET_ZOOM_IMAGE_SRC = 'SET_ZOOM_IMAGE_SRC',
}

interface SetCountryCodeAction {
  payload: State['countryCode']
  type: typeof Actions.SET_COUNTRY_CODE
}

interface SetCursorOverlayTypeAction {
  payload: State['cursorOverlayType']
  type: typeof Actions.SET_CURSOR_OVERLAY_TYPE
}

interface SetIsAudioTourPlayingAction {
  payload: State['isAudioTourPlaying']
  type: typeof Actions.SET_IS_AUDIO_TOUR_PLAYING
}

interface SetIsIntroViewedAction {
  payload: State['isIntroViewed']
  type: typeof Actions.SET_IS_INTRO_VIEWED
}

interface SetIsWebPSupportedAction {
  payload: State['isWebPSupported']
  type: typeof Actions.SET_IS_WEB_P_SUPPORTED
}

interface SetPageTemplateAction {
  payload?: State['pageTemplate']
  type: typeof Actions.SET_PAGE_TEMPLATE
}

interface SetProjectsAction {
  payload: State['projects']
  type: typeof Actions.SET_PROJECTS
}

interface SetZoomImageSrcAction {
  payload: State['zoomImageSrc']
  type: typeof Actions.SET_ZOOM_IMAGE_SRC
}

export type Action =
  | SetCountryCodeAction
  | SetCursorOverlayTypeAction
  | SetIsIntroViewedAction
  | SetIsAudioTourPlayingAction
  | SetIsWebPSupportedAction
  | SetProjectsAction
  | SetPageTemplateAction
  | SetZoomImageSrcAction

const AppStateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case Actions.SET_COUNTRY_CODE:
      return {
        ...state,
        countryCode: action.payload,
      }

    case Actions.SET_CURSOR_OVERLAY_TYPE:
      return {
        ...state,
        cursorOverlayType: action.payload,
      }

    case Actions.SET_IS_AUDIO_TOUR_PLAYING:
      return {
        ...state,
        isAudioTourPlaying: action.payload,
      }

    case Actions.SET_IS_INTRO_VIEWED:
      return {
        ...state,
        isIntroViewed: action.payload,
      }

    case Actions.SET_IS_WEB_P_SUPPORTED:
      return {
        ...state,
        isWebPSupported: action.payload,
      }

    case Actions.SET_PROJECTS:
      return {
        ...state,
        projects: [...action.payload],
      }

    case Actions.SET_PAGE_TEMPLATE:
      return {
        ...state,
        previousPageTemplate: state.pageTemplate,
        pageTemplate: action.payload,
      }

    case Actions.SET_ZOOM_IMAGE_SRC:
      return {
        ...state,
        zoomImageSrc: action.payload,
      }

    default:
      return state
  }
}

export default AppStateReducer
