import { css, FlattenSimpleInterpolation } from 'styled-components'
import { mediaQueries } from './mediaQueries'
import { rem } from './rem'
import { spacings } from '../tokens/Spacings'

export const gridColumnsRoot = 4
export const gridColumnsTablet = 8
export const gridColumnsDesktop = 16

export const grid = (): FlattenSimpleInterpolation => css`
  display: grid;
  max-width: ${rem(spacings.sectionMaxWidth)};
  margin: 0 auto;
  grid-template-columns: repeat(${gridColumnsRoot}, minmax(0, 1fr));
  column-gap: ${rem(16)};
  padding-left: ${rem(spacings.side.root)};
  padding-right: ${rem(spacings.side.root)};

  ${mediaQueries.tablet} {
    grid-template-columns: repeat(${gridColumnsTablet}, minmax(0, 1fr));
    column-gap: ${rem(32)};
    padding-left: ${rem(spacings.side.tablet)};
    padding-right: ${rem(spacings.side.tablet)};
  }

  ${mediaQueries.desktop} {
    grid-template-columns: repeat(${gridColumnsDesktop}, minmax(0, 1fr));
    column-gap: ${rem(32)};
    padding-left: ${rem(spacings.side.desktop)};
    padding-right: ${rem(spacings.side.desktop)};
  }
`

export const fullwidthGridColumn = (): FlattenSimpleInterpolation => css`
  grid-column: 1 / span ${gridColumnsRoot};

  ${mediaQueries.tablet} {
    grid-column: 1 / span ${gridColumnsTablet};
  }

  ${mediaQueries.desktop} {
    grid-column: 1 / span ${gridColumnsDesktop};
  }
`
