import { createGlobalStyle } from 'styled-components'
import { rem } from '../../../../utils/rem'

const GlobalText = createGlobalStyle`
  body {
    font-size: ${rem(16)};
    font-family: ${({ theme }): string => theme.text.fontFamily};
    color: ${({ theme }): string => theme.text.color};

    i {
      font-style: italic;
    }
  }
`

export default GlobalText
